#back_div{
    background: rgba(245, 245, 245,0.3);
}
#con_div{
    position: relative;
}
/* 로딩 페이지 */
#pageread_loading{
    z-index: 9999999;
    position: fixed;
    width: 100%;    
    height: 100%; 
    background-color : rgba(100,100,100,0.6);
}
#pageread_loading img{
    position: relative;
    top : 35%;
    left: 50%;
}
/* 최상위 이동 버튼 */
#read_bring_top_btn{
    position: fixed;
    bottom: 100px;
    right: 50px;

    width:60px; 
    height:60px; 
    line-height:50px;

    text-align:center;

    border: 1px solid  rgba(216, 216, 216, 0.3);
    border-radius: 50%;
    background-color: rgba(216, 216, 216, 0.3);

    z-index: 10000000;

    cursor: pointer;
    
   
}
#read_bring_top_btn > img{
    max-width:70%; 
    max-height:70%;
    vertical-align:middle;
}
/* 상단 페이지 읽기 퍼센테이지*/
.pageRead_read_percent{
    position: fixed;
    top : 70px;
    width: 100%;
    z-index: 10000000;
    height: 10px;
}
.pageRead_read_percent .p-progressbar{
    height: 7px;
}
.pageRead_read_percent .p-progressbar .p-progressbar-value{ 
    background-color: rgb(230,230,50);
}
.pageRead_read_percent .p-progressbar .p-progressbar-label{
    line-height: 7px;
}
/* 컨텐츠 아레아 */


/* 뒤로가기*/
.pageread_back_div{
    max-width : 1100px;
    margin : 0 auto;
    padding : 15px 0 15px 0;
}
/* 본문 내용*/
.pageRead_main_div{
    padding-top: 20px;
    background-color: white;

    max-width: 1100px;
    margin : 0 auto;
    padding : 0 40px; 

    border : 1px solid #d1d5da;
    border-radius: 3px;
}
#pageread_back_icon{
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}


/* 상단 썸네일 */


.pageread_top_div{
    position: relative;
    height: 300px;
    width: 100%;
    overflow: hidden;
}
.pageread_top_div .top_thumnail_img{
    width: 100%;
    max-height: 300px;
    position: relative;
    background-color: rgba(0, 0,0, 0.7);

    z-index: 1;
}
.pageread_top_div .top_thumnail_img img{    
    min-height: 300px; 
    max-height: 500px;
    min-width: 100%;
    max-width: 100%;
    width:auto; 
    height:auto;
    margin : auto;

    opacity:0.1!important; 
    filter:alpha(opacity=70);

    top : 0;
    bottom : 0;
    left : 0;
    right : 0;
}
.pageRead_main_div .top_thumnail_title{
    position: absolute;    
    display: table;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.pageRead_main_div .top_thumnail_title h1{
    text-align: center;
    display: table-cell;
    margin : auto;
    padding-left :5%;
    padding-right : 5%;
    vertical-align: middle;
    color : white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.pageread_modify_div{
    position: absolute;
    top : 0;
    right : 0;
}

/* 수정 버튼 */
.read_modify_content{
    margin-bottom: 10px;
}
/* 상단 제목 부분 */
.top_content{
    padding-top : 1%; 
    padding-bottom : 1%; 
}
.top_content #top_info{
    margin-right: 20px;
}
.top_content #top_info i{
    font-size: 15px;
}

/* 본문 페이지 패딩 적용 */
.pageread_pagepadding{
    padding-left: 0%;
    padding-right: 0%;
}
/* 상단 태그 부분 */
.tag_content{
    margin-top: 20px;
    margin-bottom: 20px;
}
.tag_content .pi-tag{
    font-size : 14px;
}
/* .tag_content .p-button.p-button-text-only .p-button-text{
    font-size: 16px;
} */
#tag_item_span{
    font-size : 14px;
    margin-right: 10px;
    color : rgb(9, 0, 133);
    font-weight: bold;
    white-space: pre-wrap;
}
/* 본문 컨텐츠 */
.mid_content{
    margin-top: 20px;
    margin-bottom: 20px;
}
.mid_content .rdw-editor-wrapper .rdw-editor-main{
    height: auto; 
}
.mid_content img{
    max-width: 100%;
}
.mid_content .rdw-image-imagewrapper{
    width: 100%;
}


/* 프로필 관련 */
.profile{
    border : 1px solid rgb(179, 179, 179);
    border-radius: 10px;
}
#profile_img{
    width: 50px;
    height: 50px;

    border-radius: 50%;
}
.profile_ul{
    list-style: none;
    margin : 0px;
    padding-top: 20px;
}
.profile_ul li{
    font-size: 16px;
    text-align: center;
}

.profile{
   display: flex;
   justify-content: left;
   align-items: center;
}

#profile_name{
    margin-left: 100px;
    height: auto; 
    font-size: 16px;
    font-weight: bold;
    vertical-align:top;
}

/* 댓글 관련 */
.read_comment{
    margin-top: 50px;
}
/* 댓글 작성 */
.read_comment .read_comment_write_box{
    list-style: none;
    display: inline-block;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0px;
}
.read_comment .read_comment_write_box li{
    float: left;
}
/* 댓글 작성 - 텍스트 입력창 */
.read_comment .read_comment_write_box #read_comment_text_li{
    width: 70%;
}
.read_comment .read_comment_write_box #read_comment_text_li textarea{
    width: 100%;
}
/* 댓글 작성 - 비밀번호 입력 */
.read_comment .read_comment_write_box #read_comment_passwd{
    margin-left: 1%;
    width: 10%;
}
.read_comment .read_comment_write_box #read_comment_passwd input{
    width: 100%;
}
/* 댓글 작성 - 등록 버튼 */
.read_comment .read_comment_write_box #read_comment_btn{
    margin-left: 1%;
}
/* 댓글 리스트 */
/* 댓글에 사용하는 기본 아이콘 크기 */
.read_comment_list .pi{
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
}
/* 오리지날 -> depth 0 */
.read_comment_list{
    padding-left: 0;
}
.read_comment_list .read_comment_ori{
    list-style: none;
    margin-top: 15px;
}
/* 댓글 상단 텍스트 정보 */
.read_comment_list .read_comment_ori span{
    font-size: 14px;
    font-weight: lighter;
}
/* 날짜 */
.read_comment_list .read_comment_ori #read_comment_ori_date_icon{
    margin-left: 10px;
}

/* 이미지 아이콘 */
.read_comment_list .read_comment_ori #read_comment_icons{
    width: 12px;
    margin-left: 10px;
    cursor: pointer;
}
/* 댓글 본문 */
.read_comment_list .read_comment_ori .read_comment_text p{
    margin : 0;
    padding : 0;
    margin-left: 5px;
    margin-top: 5px;
    font-size: 16px; 
}
/* 댓글 달기 */
.read_comment_list .read_comment_ori .read_comment_reply p{
    margin : 0;
    padding : 0;
    margin-left: 5px;
    margin-top: 15px;
    font-size: 12px;
    color : gray;
    cursor: pointer; 
}
/* 대댓글*/
.read_comment_reply ul{
    margin : 0;
    padding : 0;
    margin-top: 10px;
    margin-left: 0px;
    list-style: none;
    display: inline-block;
    width: 100%;
}
.read_comment_reply li{
    float : left;
    margin-left: 10px;
}
/* 대댓글 작성 */
.read_comment #read_comment_reply_li{
    width: 60%;
}
.read_comment #read_comment_reply_li textarea{
    width: 100%;
}
.read_comment #read_comment_reply_passwd{
    width: 10%;
}
.read_comment #read_comment_reply_passwd input{
    width: 100%;
}
/* 대댓글 리스트 */
.read_comment_reply_list ul{
    margin : 0;
    padding : 0;
    margin-top: 5px;
    margin-left: 30px;
    list-style: none;
    display: inline-block;
    width: 100%;
}
/* 대댓글 리스트 텍스트 */
.read_comment_reply_text p{
    margin : 0;
    padding : 0;
    margin-left: 20px;
    margin-top: 5px;
    font-size: 16px;
}
/* pc */
@media(max-width : 1367px){
    /* 본문 내용
    .pageRead_main_div{
        
    } */
    /* 뒤로가기 */
    /* .pageread_back_div{
        
    } */
    /* 상단 제목 부분 */
    .top_content{
        margin-left : 0.5%;
        padding-top : 1%; 
        padding-bottom : 1%; 
    }
    .top_content #top_info{
        margin-right: 15px;
    }
    .top_content h1{
        font-size: 26px;
    }
    /* 상단 태그 부분 */
    .tag_content{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .tag_content .p-button.p-button-text-only .p-button-text{
        font-size: 14px;
    }
    /* 본문 컨텐츠 */
    .mid_content{
        margin-top: 15px;
        margin-bottom: 15px;
    }
    /* 에디터 속 글 컨트롤 */
    .mid_content h1{
        font-size: 24px;
    }
    .mid_content h2{
        font-size: 22px;
    }
    .mid_content h3{
        font-size: 20px;
    }
    .mid_content h4{
        font-size: 18px;
    }
    .mid_content h5{
        font-size: 16px;
    }
    .mid_content h6{
        font-size: 14px; 
    }
    /* 이미지 */
    .mid_content img{
        max-width: 105%;
        margin-left: -2.5%;
    
    }
    .mid_content .public-DraftStyleDefault-ul{
        font-size: 16px;
    }
}

/* tablet */
@media(max-width : 1025px){
    /* 본문 내용*/
    .pageRead_main_div{
        max-width : 900px;
    }
    /* 뒤로가기 */
    .pageread_back_div{
        max-width : 900px;   
    }
    /* 상단 제목 부분 */
    .top_content{
        margin-left : 0.5%;
        padding-top : 1%; 
        padding-bottom : 1%; 
    }
    .top_content #top_info{
        margin-right: 10px;
    }
    .top_content h1{
        font-size: 24px;
        margin : 0px;
        margin-top: 10px;
    }
    /* 상단 태그 부분 */
    .tag_content{
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .tag_content .p-button.p-button-text-only .p-button-text{
        font-size: 13px;
    }
    /* 본문 컨텐츠 */
    .mid_content{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    /* 에디터 속 글 컨트롤 */
    .mid_content h1{
        font-size: 22px;
    }
    .mid_content h2{
        font-size: 20px;
    }
    .mid_content h3{
        font-size: 18px;
    }
    .mid_content h4{
        font-size: 16px;
    }
    .mid_content h5{
        font-size: 14px;
    }
    .mid_content h6{
        font-size: 12px;
    }
    .mid_content img{
        max-width: 105%;
        margin-left: -2.5%;
    }
    
    .mid_content .public-DraftStyleDefault-ul{
        font-size: 16px;
    }
    
}

@media(max-width : 801px){
    /* 본문 내용*/
    .pageRead_main_div{
        max-width : 700px;
    }
    /* 뒤로가기 */
    .pageread_back_div{
        max-width : 700px;   
    }
    .top_content h1{
        font-size: 24px;
    }
    .pageread_back_div{
        padding-top: 15px;
        margin-top: 50px;
    }
    /* 뒤로가기 */
    .pageread_back_div{
        margin-left: 3%;
    }
    /* 상단 태그 부분 */
    .tag_content{
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .tag_content .p-button.p-button-text-only .p-button-text{
        font-size: 13px;
    }
    /* 본문 컨텐츠 */
    .mid_content{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    /* 에디터 속 글 컨트롤 */
    .mid_content h1{
        font-size: 20px;
    }
    .mid_content h2{
        font-size: 18px;
    }
    .mid_content h3{
        font-size: 16px;
    }
    .mid_content h4{
        font-size: 14px;
    }
    .mid_content h5{
        font-size: 12px;
    }
    .mid_content h6{
        font-size: 10px;
    }
    .mid_content .public-DraftStyleDefault-ul{
        font-size: 14px;
    }
    
}

/* phone */
@media(max-width : 415px){
    /* 로딩화면 */
    #pageread_loading img{
        position: relative;
        top : 35%;
        left: 45%;
    }
    /* 본문 내용*/
    .pageRead_main_div{
        padding : 0 10px; 
    }
    .top_content h1{
        font-size: 18px;
        line-height: 22px;
    }
    /* 상단 태그 부분 */
    .tag_content{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .tag_content .p-button.p-button-text-only .p-button-text{
        font-size: 13px;
    }
    /* 본문 컨텐츠 */
    .mid_content{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    /* 에디터 속 글 컨트롤 */
    .mid_content h1{
        font-size: 18px;
    }
    .mid_content h2{
        font-size: 16px;
    }
    .mid_content h3{
        font-size: 14px;
    }
    .mid_content h4{
        font-size: 12px;
    }
    .mid_content h5{
        font-size: 10px;
    }
    .mid_content h6{
        font-size: 8px;
    }
    /* 이미지 */
    .mid_content img{
        max-width: 120%;
        max-height: 300px;
        margin-left: -10%;
    }

    /* 최상위로 이동 버튼 */
    #read_bring_top_btn{
        bottom: 30px;
        right: 20px;

        width:50px; 
        height:50px; 
        line-height:40px;
    }
    /* 하단 프로필 */
    .profile .profile_ul{
        padding : 3px;
    }
    .profile_ul li{
        font-size: 14px;
    }
    .profile_ul li p{
        width: 100%;
        display: block;
        word-break : keep-all;
    }
    #profile_name{
        margin-left: 10px; 
        font-size: 14px;
        
    }
    /* 댓글 달기 */
    /* 댓글 작성 - 텍스트 입력창 */
    .read_comment .read_comment_write_box #read_comment_text_li{
        width: 100%;
    }
    /* 댓글 작성 - 비밀번호 입력 */
    .read_comment .read_comment_write_box #read_comment_passwd{
        margin-left: 0px;
        width: 50%;
    }
    .read_comment .read_comment_write_box #read_comment_passwd input{
        width: 100%;
    }
    /* 댓글 작성 - 등록 버튼 */
    .read_comment .read_comment_write_box #read_comment_btn{
        margin-left: 1%;
    }
    /* 대댓글 리스트 */
    .read_comment_reply_list ul{
        margin-left: 5px;
    }

    .pageRead_read_percent{
        top : 50px;
    }

}

@media(max-width : 366px){

    .top_content h1{
        font-size: 16px;
        line-height: 20px;
    }
    /* 상단 태그 부분 */
    .tag_content{
        margin-top: 18px;
        margin-bottom: 18px;
    }
    .tag_content .p-button.p-button-text-only .p-button-text{
        font-size: 11px;
    }
    /* 에디터 속 글 컨트롤 */
    .mid_content h1{
        font-size: 19px;
    }
    .mid_content h2{
        font-size: 17px;
    }
    .mid_content h3{
        font-size: 15px;
    }
    .mid_content h4{
        font-size: 13px;
    }
    .mid_content h5{
        font-size: 11px;
    }
    .mid_content h6{
        font-size: 9px;
    }
}