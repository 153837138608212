/* 최상위 화면 */
.trend_main{
    background-color : rgb(245, 245, 245);
}
/******************************/
/********탑***************/
/****************************/

/* 해더 부분 콘 아레아 */
.trend_head_con_area{
    min-width: 100%; 
    min-height: 250px;
    /* 백그라운드 이미지 */
    background:linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8) ),  url("https://teamnovatech.co.kr/_nova/_img/trend_bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;  

}
/* 타이틀 */
.trend_head_con_area .head_title{
    max-width: 700px;
    margin : 0px auto;
    text-align: center;
    color : white;
    padding-top : 80px;
    word-break: keep-all;
}
/* 검색 */
.trend_head_con_area .head_input{ 
    max-width: 600px;
    margin : 50px auto;
    padding : 3px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    background-color:  rgba(170, 170, 170,0.5);
    border-radius: 5px;
}
.trend_head_con_area .head_input input{
    position: relative;
    height: 50px;
    background-color : transparent;
    border : none;
    min-width: 80%;
    color : white;
    font-size : 16px;
}
.trend_head_con_area .head_input input::placeholder{
    color : white;
    font-size : 16px;
}
.trend_head_con_area .head_input input:focus{
    outline: none !important;
    border : none !important;
}

.trend_head_con_area .head_input img{
    display : inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    cursor: pointer;
}

/******************************/
/********바디***************/
/****************************/
/* 내부 아이템 콘텐츠 아레아 */
.trend_mid_con_area{
    max-width: 1200px;
    margin : 0px auto;

    background-color : transparent;

    text-align: center;

}

/**************** 인기 검색어 *************/

/* 타이틀 */
.trend_mid_con_area .pop .title{
    text-align: left;
}
/* 서브 타이틀 */
.trend_mid_con_area .pop .sub_title{
    text-align: left;
    color : gray;
}
/* 콘텐츠 div */
.trend_mid_con_area .pop .content{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: left;

    align-items: center;
    justify-content: left;
    vertical-align: middle;
}
/* 콘텐츠 속 아이템 */
.trend_mid_con_area .pop .content .items{
    display: flex;
    flex: 1;
    border : 0.3px solid rgba(150,150,150,0.1);
    padding : 20px;

    align-items: center;
    justify-content: left;
    vertical-align: middle;

    min-width: 320px;
    height: 115px;
    
    
}
.trend_mid_con_area .pop .content .items:hover{
    background-color : rgba(200,200,200,0.1); 
    cursor: pointer;
}
.trend_mid_con_area .pop .content .items #title{
    flex : 3;
    max-width: 50%;
    font-size: 18px;
    vertical-align: middle;

    word-break: keep-all;
    
}
.trend_mid_con_area .pop .content .items #count{
    flex : 1;
    margin-left: 20px;
    font-size: 14px;
    color : rgba(0,0,0,0.54);
    word-break : break-all;
}

.trend_mid_con_area .pop .content .items #chart{
    flex :2;
    margin-left: 20px;
}
/* 인기 검색어 관심도 변화 차트 */
.time_graph #left_chart #time_graph_chart{
    width: 100%;
    display: inline-flex;
    align-items: center;
}

.time_graph #left_chart #time_graph_chart i{
    font-size: 30px;
    color : #CCCCCC;
    padding-top : 30px;
    padding-bottom: 30px;
}
.time_graph #left_chart #time_graph_chart i:hover{
    color : #111111;
    cursor: pointer;
}


/* ********************************************************* */
/* ********************************************************* */
/* *****************검색 검색 검색 검색 ********************* */
/* ********************************************************* */
/* ********************************************************* */

.search_head_con_area{
    min-width: 100%;
    /* border-bottom: 3px solid rgba(0,0,0,0.5); */
    box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.3);
    background-color : rgba(255,255,255,0.3);
}


.search_head_con_area .search_box_con .search_box{
    max-width: 1200px;
    margin : 0px auto;
    display: flex;
    min-width: 300px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    vertical-align: middle;
}

.search_head_con_area .search_box_con .search_box .item{
    display: flex;
    flex: 1;
    border: 1px solid rgba(0,0,0,0.2);
    text-align: center; 

    align-items: center;
    justify-content: center;
    vertical-align: middle;
    
    min-height: 150px;
}
.search_head_con_area .search_box_con .search_box .item:hover{
    background-color :  rgba(0,0,0,0.1);
}

/* 기존 검색어 */
.search_head_con_area .search_box_con .search_box .item #circle{
    flex: 0.5;
}
.search_head_con_area .search_box_con .search_box .item #circle div{
    width: 20px;
    height: 20px;
    margin : 0px auto;
    border-radius: 50%;
}
.search_head_con_area .search_box_con .search_box .item #title{
    flex: 2;
    text-align: left;
    font-size: 30px;
    font-weight : 500;
    padding-bottom: 5px;
}
.search_head_con_area .search_box_con .search_box .item #delete{
    flex: 0.5;
    font-size : 22px;
    cursor: pointer;
}

/* 새로운 검색어 추가 */
/* 상단 헤더 부분 */
.search_head_con_area .search_box_con .search_box .item #add input{
    flex:2;
    background-color: transparent;
    border : none;
    height: 50px; 
    font-size: 24px;
}
.search_head_con_area .search_box_con .search_box .item #add input::placeholder{
    font-size: 24px;
}
.search_head_con_area .search_box_con .search_box .item #add input:focus{
    outline: none !important;
    border : none !important;
}

.search_head_con_area .search_box_con .search_box .item #plus{
    flex:0.5;
    font-size : 22px;
    color : gray;
    cursor: pointer;
}

.search_compare_picker_area {
    min-width: 100%;
    border-top: 1px solid rgba(0,0,0,0.2);
}

.con_area{
    display: flex;
    max-width: 1200px;
    margin:0px auto;

    
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    vertical-align: middle;
}
.con_area .p-menubar{
    border : none;
    background-color : transparent;
}

.con_area .p-menuitem-text{
    font-size: 16px; 
    font-weight : bold;
}
/* 바디 부분 */
/* 관심도 변화 */
.search_body_con_area{
    min-width: 100%;
}
.search_body_con_area .wallpaper{
    max-width: 1200px;
    margin : 20px auto;
}
/* 차트 부분 */
.search_body_con_area .wallpaper .time_graph{
    max-width: 100%;

    border : 1px solid rgba(0,0,0,0.1);
    border-radius: 3px;
    background-color : white;
}
/* 제목 */
.search_body_con_area .wallpaper .time_graph #title{
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}
.search_body_con_area .wallpaper .time_graph #title h3{
    padding : 10px 10px 10px 30px;
}
/* 차트 */
.search_body_con_area .wallpaper .time_graph #left_chart{
    display: block;
    padding : 10px;    
}
/* 찾을 수 없는 데이터 */
.search_body_con_area .wallpaper #not_found{
    text-align: center;
    color : rgba(0,0,0,0.7); 
}
.search_body_con_area .wallpaper img{
    padding-top : 20px;

}

/* 연관검색어 */
.search_body_con_area .wallpaper .related_search{
    max-width: 100%;
    border : 1px solid rgba(0,0,0,0.1);
    border-radius: 3px;
    background-color : white;

    margin-top: 30px;
}

.search_body_con_area .wallpaper .related_search #title{
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.search_body_con_area .wallpaper .related_search #title h3{
    padding : 10px 10px 10px 30px;
}


/* 반응형 페이지 제작 */

@media(max-width : 1367px){
    
}

@media(max-width : 1025px){
    .trend_mid_con_area{
        max-width: 900px;
    }
}

@media(max-width : 801px){
    .trend_mid_con_area{
        max-width: 750px;
    }
}

@media(max-width : 415px){
    .trend_head_con_area{
        min-height: 50px; 
    }
    .trend_head_con_area .head_title{
        display: none;
    }
    .trend_head_con_area .head_input{ 
        margin : 0px;
    }
    .trend_mid_con_area{
        max-width: 380px;
    }
    .trend_mid_con_area .pop .content .items{
        min-width: 300px;
    }
}

@media(max-width : 376px){
    .trend_mid_con_area{
        max-width: 320px; 
    }

}
@media(max-width : 361px){
    .trend_mid_con_area{
        max-width: 300px; 
    }

}