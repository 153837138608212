.admin_main{
    margin-top: 70px;
    margin-bottom : 800px;
    margin-left: 10%;
    margin-right: 10%;
}

.admin_card_box ul{
    margin : 0;
    padding : 0;
    list-style: none;
    cursor: pointer;
}

.admin_card_box li{  
    /* width: 200px; */
    height: 150px;
    margin-right: 20px;
    display: inline-block;
    text-align: center;
}

.admin_card_box li div:hover{ 
    background: #000;
    color : #fff;
}

/* 댓글 관리 버튼 설정 */
#comment_btn_re{
    background-color : green;
    color : white;
    font-weight: bold;
}
#comment_btn_del{
    background-color : red;
    color : white;
    font-weight: bold;
}