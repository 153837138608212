.list{
    display: table;
    width: 100%;
    background-color: rgb(240, 240, 240); 
}

/* **********텍스트 로고************ */
.list #our_name{
    display: table-cell;
    width: 30%;
    text-align: center;
    vertical-align: top;
}
/* *******************푸터 블럭******************** */
/* 공용으로 사용하는 클레스의 블럭 속성입니다. */ 
.list .foot_block{
    display: table-cell;
    padding-top: 10px;
    padding-right: 5%;
    text-align: right;
}
/* 리스트 */
.list .foot_block ul{
    display: inline-block;
    padding-left:5%; 
    vertical-align: top;
    text-align: left;
}
.list .foot_block ul li {
    list-style: none;
    margin-bottom : 5px;
    margin-right: 5px;
    margin-left: 5px;
    
}

/* ***************패밀리 사이트******************* */
/* 각 리스트의 타이틀 */
.list .foot_block .our_site_list_title{ 
    font-size: 20px;
    font-weight: bold;
}
.list .foot_block a{
    color : black;
}
/*****************카피라이트 영역******************** */
.foot_inc{
    padding: 10px;
    padding-left: 50px;
    background-color: rgb(240, 240, 240); 
    text-align: left;
}

.foot_inc #foot_text{
    display: inline-block;
    font-size: 12px;
    font-weight: lighter;
}


/* 반응형 디자인 */
/* pc */
@media(max-width: 1367px){
   
}

/* tablet */
@media(max-width : 1025px){
    /* 팀노바 이름 */
    .list #our_name{
        width: 15%;
    }
    .list #our_name h1{
        font-size: 18px;
        margin-top: 20px;
    }

    /* 푸터 블럭 공용 */
    .list .foot_block{
        display: table-cell;
        padding-top: 0px;
        padding-right: 20px;
        text-align: right;
    }
    /* 리스트 */
    .list .foot_block ul{
        display: inline-block;
        padding-left:40px; 
        vertical-align: top;
        text-align: left;
    }
    .list .foot_block ul li {
        list-style: none;
    }
}

@media(max-width : 801px){
    /* 팀노바 이름 */
    .list #our_name{
        width: 15%;
    }
    .list #our_name h1{
        font-size: 16px;
        margin-top: 22px;
    }

    /* 푸터 블럭 공용 */
    .list .foot_block{
        padding-top: 0px;
        padding-right: 10px;
        text-align: right;
    }
    /* 리스트 */
    .list .foot_block ul{
        display: inline-block;
        padding-left:10px; 
        vertical-align: top;
        text-align: left;
    }
    .list .foot_block ul li {
        list-style: none;
    }
    /* 타이틀 */
    .list .foot_block .our_site_list_title{
        font-size: 16px;
    }
    /* 내용 */
    .list .foot_block li{
        font-size : 12px;
    }
}

/* phone */
@media(max-width : 415px){
    
    .list #our_name{
        display: none;
    }

    /* 푸터 블럭 공용 */
    .list .foot_block{
        text-align: center;
    }

    .list .foot_block ul{
        display: block;
        text-align: center;
    }

    /* 타이틀 */
    .list .foot_block .our_site_list_title{
        font-size: 14px;
    }

    /* 내용 */
    .list .foot_block li{
        font-size : 12px; 
    }

}