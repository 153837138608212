/* 전체 스타일 */
#Home{
    background-color: rgb(245, 245, 245);
    height: auto;
}
/* 상단 커버 이미지 및 텍스트 스타일 */
#cover{
    height: 300px;
    text-align: center;
    vertical-align: middle;
    padding-top: 80px;
    background:linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),  url("https://teamnovatech.co.kr/_nova/_img/main.jpg");

}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
#cover #cover-inner .cover_text{
    width: 100%;
    color : white; 
}

/* 중단 컨텐츠 스타일 */
#con_area{
    position : relative;
    width: 100%;
    max-width: 1300px;
    min-height: 500px;
    margin : 0 auto;
}
#Content{
    position: relative;
    margin: 0 40px;
    max-width: 1250px;
    
}
/* 홈 컨텐츠 */
#Content .home_list_container{
    width: 75%;
}
#Content .home_list_container h3{
    margin-left: 10px;
}
#Content #home_chart{
    width: 75%;
}
/* 사이드 콘텐츠 스타일 */
#side_content{
    position: absolute;
    width : 270px;
    top: 0px;
    right: 0px;
    
}
#side_content h3{
    margin-top: 0px;  
}
#side_content ul{
    list-style: none;
    padding : 0;
    padding-left : 5px;
    border-radius: 5px;
    background-color : white;
}
#side_content li{
    cursor: pointer;
    padding-top : 5px;
    padding-bottom : 5px;
    padding-right : 5px;
}
#side_content ul a{
    color : black;
}
#home_top5_index{
    width: 10px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
    font-weight: bold;
    padding-right: 20px;
    padding-left: 5px;
    font-size: 14px;
}
#home_top5_title{
    width: 230px;
    display: inline-block;
    color : gray;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
    font-size: 14px;
}
#home_top5_title:hover{
    color : black;
    font-weight: bold;
}

/* 반응형 디자인 */
/* pc */
@media(max-width: 1367px){
    /* 배너 속성 */
    #cover{
        height: 250px;
        padding-top: 55px;
    }
}

/* tablet */
@media(max-width : 1025px){
    /* 배너속성 */
    #cover{
        height: 200px;
        padding-top: 30px;
    }
    /* 사이드 콘텐츠 스타일 */
    #side_content{
        width : 200px;
    }
    #home_top5_title{
        width: 160px;
    }
}
/* tablet */
@media(max-width : 801px){
    #Content .home_list_container{
        width: 100%;
    }
    /* 사이드 콘텐츠 스타일 */
    #side_content{
        display: none;
        
    }
    #Content #home_chart{
        width: 100%;
    }
}


/* phone */
@media(max-width : 415px){
    /* 커버 */
    #cover{
        height: 150px;
        padding-top: 35px;
    }
    /* 타이틀 */
    #cover h1{
        font-size: 18px;
    }
    #cover p{
        font-size: 10px;
    }
    #Content{
        margin: 0 10px;
    }
}