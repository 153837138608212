/* 배경 css */
.bg_div{
    background: white;
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    display: flex;
    flex-basis :auto;
    flex-direction: row;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}
/* 공용 css */
.data_items{
    height: 100%;
    margin: 0 auto;
    color : black;
}

/***************썸네일*********************/
#lv_label{
    padding-top: 10px;
    position: absolute;
    z-index: 1;
    -webkit-transform: skew(20deg,-20deg);
   -moz-transform: skew(20deg,-20deg);
   transform: skew(20deg,-20deg); 
   font-weight: bold;
   font-size: 15px;
}
#data_items_thumnail{
    width: 20%;
    height: 90%;
    text-align: center;    
}
#data_items_thumnail img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit : scale-down;
}

/* 아이템의 좌측 콘텐츠.
    제목, 요약 내용, 태그가 들어가게 됩니다. */
#data_items_left_content{
    width: 55%;
    /* height: 90%; */
    text-align: left;
}

/* 좌측 컨텐츠 본문  */
/* 타이틀 */
#data_items_left_content .list_title{
    width: 100%;
    margin-top : 10px;
    margin-bottom : 10px;

    vertical-align: bottom;
    overflow: hidden;
    display :inline-block;
    text-overflow:ellipsis;

    font-family: "Noto Sans CJK KR";

    color : black;
    text-align: left;
    font-weight: bolder ;
    font-size: 20px;
}
/* 서브타이틀 */
#data_items_left_content .list_subtitle{
    display :inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    resize: none;
    
    border : 0;
    margin-bottom: 10px;
    width: 100%;
    height: 30%;

    text-align: left;
    color : black;
    font-weight: bold;
    font-size: 16px;

    line-height: 30px;
}




/* 아이템 우측 콘텐츠
    유저 정보가 들어가게 됩니다.
    프로필 사진, 유저 이름 */
#data_items_right_content{
    position: relative;
    width: 18%;
    height: 100%;    
    margin : 0 auto;
    text-align: center;
    
    
}
#right_content_conarea{
    position: absolute;
    max-width: 100%;
    height: 130px;
    margin : auto;
    top :0;
    bottom : 0;
    left : 0;
    right : 0;
}
#right_content_conarea p{
    max-width: 100%;
}
/* 프로필 이미지 속성 */
#right_content_img_area{
    position: relative;
    width: 60px;
    height : 60px;
    margin : 0 auto;
    line-height: 60px;
    border: 1px solid rgba(223, 223, 223,0.5);
    border-radius: 5px;
    vertical-align: middle;
}
#data_items_right_content img{
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    margin : auto;
    top :0;
    bottom : 0;
    left : 0;
    right : 0;
}

/* 구분자 */
.bg_div hr{
    margin-left: 5%;
    margin-right: 5%;
    border : solid 0.3px rgb(220, 220, 220);

}



/* **********************페이지네이션*************************** */
body .p-paginator{
    background-color : white;
    border : none;
    margin-top: 30px;
    margin-bottom: 30px;
}

/**********데이터가 없을 떄*****************/
.list_no_result{
    margin-top: 20px;
    text-align : center;
    margin-bottom: 30px;
}
.list_no_result img{
    width: 25%;
}

/* 반응형 디자인 */
/* pc */
@media(max-width: 1367px){
    
}
@media(max-width : 1025px){
    /* 썸네일 */
    #data_items_thumnail img{
        margin-top: 10px;
        width: 100%;
        padding : 0;
    }
}
@media(max-width : 801px){
    .bg_div{
        display: block;
        height: auto;
    }
    /* 썸네일 */
    #data_items_thumnail{
        text-align: center;
        width: 100%;
        height: 200px;
    }
    #data_items_thumnail img{
        margin : 0;
        padding : 0;
    }
    /* 텍스트 공용*/
    #data_items_left_content {
        width: 100%;
    }
    /* 타이틀 */
    #data_items_left_content .list_title{
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: 16px;
        color : black;
        font-weight: bold;
    }
    /* 서브타이틀 */
    #data_items_left_content .list_subtitle{
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: 14px;
        color:gray;
        font-weight: normal;
    }
    /* 태그 리스트 */
    #data_items_left_content .tag_group{
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    
    .tag_group #tag_title { 
        display: none;
        font-size: 16px;
        color : black;
    } 
    /* 유저정보 */
    #data_items_right_content{
        display: none;
       
    }
}

/* phone */
@media(max-width : 415px){
    /* 타이틀 */
    #data_items_left_content .list_title{
        font-size: 16px;
    }
    /* 서브타이틀 */
    #data_items_left_content .list_subtitle{
        font-size: 12px;
    }
}