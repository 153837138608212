/* 네비게이션 css 설정 */
#Nav{
    width:100%;
    height: 70px;    
    background:rgb(49, 49, 49);
    position: fixed;
    top: 0;
    z-index: 20000000;
}
/* 네비게이션에 들어가는 모든 아이템들 공용 클레스 */
.Nav-Item{
    vertical-align: bottom;
    float : left;
}
/* 아이템 공용 클레스 중 ul li 속성만 */
.Nav-Item ul li{
    list-style: none;
    text-align: center;    
    line-height :40px;
}
/* 모바일 네비게이션 메뉴 */
#Mobile_Menu{
    float: left;
    display: none;
}

/* 이미지 20% , 메뉴 60% 로그인 20% */
#Nav-Logo{
    padding: 0;
    margin:0;
    margin-top: 15px;
    padding-left: 20px;
    width: 100px;
}
/* 메뉴 */
#Nav-Menu{
    display: inline-block;
    text-align: center;
}
#Nav-Menu ul li{
    float : left;
}

/*언어 변경*/
#nav_flag_con{ 
    height: 100%;
    float:right;
    padding-top: 25px;
}
#nav_flag_con button{
    background: transparent;
    border: none;
    cursor: pointer;
}

#Nav-Menu .Nav-Item-Link{
    font-size : 18px;
    font-weight: bold;
    width: 100px;
    color:white;
    text-decoration: none;
    text-align: center;
    display: block;
}
/* 유저 관련 */
#nav_user{
    float: right;
    padding-right : 20px;
}
#nav_user ul li{
    float : left;
}
/* 회원가입*/
/* 타이틀 */
#link_signin{
    color : white;
    padding-right: 20px;
}
.Nav-Item #signup_border{
    border: 2px solid white;
    border-radius: 5px;
    padding-top: 3px;
    padding-bottom: 5px;
}
#link_signup{
    margin: 10px; 
    color : white;
}
/* ***********검색************* */
#nav_search{
    margin-right: 30px;
    margin-top: 3px;
}
/* 검색 드롭다운 메뉴 */
.search_dropdown_menu_none{
    display: none;
}
.search_dropdown_menu_show{
    display: block;
    background-color: white; 
    padding: 10px 5px 5px 5px;
    border-radius: 0px 0px 5px 5px;
    border : 3px solid gray;

}
.search_dropdown_menu_show div{ 
    padding : 5px 0px 5px 0px;    
    cursor: pointer;
}
.search_dropdown_menu_show h3{ 
    padding : 0;
    margin : 0;
    width: 130px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    float:left;
}

/* ************로그인 했을 때**************** */
.Nav_login_items{
    float: right;
    list-style: none;    
    margin-right: 20px;
    vertical-align: middle;
}
.Nav_login_items .p-menu{
    display: fixed;
}
.Nav_login_items li{
    float: left;
}
.Nav_login_items #nav_search{
    margin-right: 20px;
}
/* 유저 로그인 ui */
.user_login_ui {
    margin-top: 0px;
    width: 50px;    
    border: 1px solid white;
    border-radius: 3px;
    background-color: white;
}

.user_login_ui img{
    display: inline-block;
    width: 30px;
    height: 30px; 
    border: 1px solid white;
    border-radius: 3px;
    background-color: white;
}
.user_login_ui .pi-angle-down{
    font-size : 1em;
}
/* 드롭다운 메뉴 */
.user_dropdown_menu_none{
    display: none;
}
.user_dropdown_menu_show{
    margin-left : -25px;
    margin-top:0px;
    display: block;
    background-color: white;
    border-radius: 3px 0px 3px 3px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.user_dropdown_menu_show div{
    cursor: pointer;
    padding :5px 5px;
}

/* 반응형 디자인 */
/* pc */
@media(max-width: 1366px){
    /* 네비게이션 카테고리 */
    #Nav #Nav-Menu{
        width: 45%;
    }
    /* 네비게이션 우즉 로그인 회원가입을 포함한 박스 */
    #nav_user{
        padding-right : 30px;
    }
}
/* tablet */
@media(max-width : 1025px){
    #nav_user{
        display: none;
    }
    #Nav-Menu{
        display: none;
    }
    #nav_flag_con{
        display: none;
    }
    .Nav_login_items{
        display: none;
    }
    /* 로고 */
    #Nav-Logo{
        position: fixed;
        right: 40px;
    }
    /* 메뉴 햄버거 등장 */
    #Mobile_Menu{
        display: inline-block;
        width: 15%;
        height: 100%;
    }
}

/* phone */
@media(max-width : 415px){
    #Nav{
        height: 50px;
    }
    /* 로고 */
    #Nav-Logo {
        right : 10px;
        top: -5px;
    }
    #Nav-Logo img{
        width: 90%;
    }
    /* 모바일 메뉴 */
    #Mobile_Menu{
        width: 20%;
        margin-left: 20px;
    }
    /* 이미지 사이즈 줄이기 */

}