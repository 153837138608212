.write_main_div{
    padding-top: 30px;
}
/*****************전체화면************************* */
.writer_main_ui{
    max-width: 1000px;
    margin : auto;
    padding-left: 0;
    padding-right : 0;
}
.editor_editor .DraftEditor-root{
    height: 600px;
}
/* h3태그 글씨 */
.writer_main_ui h3{
    font-size: 16px;
    font-weight: bold;
}
/* ***********************에디터 상단 ui 위치 잡기***************************** */
.editor_info{
    display: inline-block;
    width: 100%;
}
.editor_info_left{
    display: inline-block;
    width: 100%;
}
/* 입력필드(본문입력 제외) */
.editor_info_left .p-inputtext{
    max-width: 100%;
}
.editor_info_left .p-inputtext{
    min-width: 100%;
}
/***************************게시판 선택*************************************/
.type_select_span{
    padding-right: 10px;
}
/* ****************************임시저장, 등록하기 버튼 ******************************* */
.editor_info_right{
    display: inline-flex;    
    vertical-align: bottom;
    text-align: right;
    float:right;
    margin-top: 250px;
}

.editor_info_right button{
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    
}
/* *********************파일업로드*************************** */
.editor_info_thum{
    display: inline-block;
}
/* 이미지 */
#thumnail_img_preview{
    width: 200px;
    height : 200px;
    border : 1px solid gray;
}

/* *******************에디터 전용************************* */
/* 에디터 전체를 감싸는 것 */
.editor_wrap{
    border: 1px solid grey;
    min-height: 200px;
}
/* 에디터에서 안쪽에 글 쓰는 부분! */
.editor_editor{
    margin-left : 10px;
    margin-right : 10px;
    min-height: 200px;
}
/* 파일 업로드 */
/* 상단 버튼들 전체 백그라운드 */
body .p-fileupload .p-fileupload-buttonbar{
    background-color : transparent;
    padding : 0;
    border:none;
}
body .p-fileupload .p-fileupload-buttonbar button{
    visibility: hidden;
}

/* 중앙 프로그레스바 */
.p-fileupload-content .p-progressbar{
    visibility: hidden;
}
/* ㅇ하단 미리보기 이미지 미리보기 */
body .p-fileupload .p-fileupload-content{
    background-color : transparent;
    padding : 0;
    border : none;
}

/* 해상도 대응 */
/* pc */
@media(max-width: 1367px){
    .writer_main_ui{
        padding-top: 50px;
        padding-left: 5%;
        padding-right : 5%;
    }
    
}

/* tablet */
@media(max-width : 1025px){
    .writer_main_ui{
        padding-top: 40px;
        padding-left: 3%;
        padding-right : 3%;
    }
    
}

@media(max-width : 769px){
    .writer_main_ui{
        padding-top: 30px;
        padding-left: 2%;
        padding-right : 2%;
    }
}

/* phone */
@media(max-width : 415px){
    .writer_main_ui{
        padding-top: 10px;
        padding-left: 1%;
        padding-right : 1%;
    }
    .editor_info_thum{
        display: block;
        text-align: center;
        width: 100%;   
    }
    .editor_info_thum input{
        width: 50%;
    }
    .editor_info_right{
        display: block;
        text-align: center;
        width: 100%;    
        margin-top: 10px;
    }
}

@media(max-width : 361px){
   
}