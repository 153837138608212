
.mypage_main_div{
    padding-top: 70px;
    padding-left: 10%;
    padding-right: 10%;  
}
/* 상단 탭 메뉴 */
.mypage_menu_item{
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}
/*******탭메뉴************/
body .p-tabmenu .p-tabmenu-nav{
    border-bottom: none;
    font-size: 14px;
}
.mypage_card{
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center; 
}

/* ********개인정보 변경******* */
/* 이미지 업로드 폼 */
#mypage_profile_upload{
    text-align: center;
}
/* 프로필 이미지 */
#mypage_profile_img{
    width: 300px;
    max-height: 300px;
    margin-bottom: 20px;
    border : 1px solid gray;
    border-radius: 10px;
}
/* 구역 나누기 */
.mypage_menu_item .mypage_div{
    padding-right: 20px;
    padding-left: 20px;
    margin: 0;
    margin-right: 2%;
    margin-left: 2%;
    display: inline-block;
    vertical-align: left;
}
/* 프로필 텍스트 적기 */
#profile_text{
    text-align: left;
    padding-bottom: 0px; 
}


/* 비밀번호 변경 */ 
.mypage_pass_ul ul li{
    list-style: none;
    margin-top: 30px;
}
.mypage_pass_ul{
    text-align: left;
}
.mypage_pass_ul .p-inputtext{
    width:30%;
}



/* 해상도 대응 */
/* pc */
@media(max-width: 1367px){
    
    .mypage_pass_ul .p-inputtext{
        width:40%;
    }
}

/* tablet */
@media(max-width : 1025px){
    .mypage_menu_item .mypage_div{
        margin-right: 2%;
        margin-left: 2%;
        padding : 0;
        vertical-align: middle; 
    }

    .mypage_main_div{
        padding-top: 50px;
        padding-left: 3%;
        padding-right: 3%;    
    }
    .mypage_pass_ul .p-inputtext{
        width:50%;
    }
}

@media(max-width : 769px){
    .mypage_menu_item .mypage_div{
        vertical-align: middle;

        padding-right: 20px;
        padding-left: 20px;
    }
    .mypage_main_div{
        padding-top: 20px;
        padding-left: 5%;
        padding-right: 5%;    
    }
   /*******탭메뉴************/ 
    body .p-tabmenu .p-tabmenu-nav{
        font-size: 14px;
    }
    /* 패스워드 */
    .mypage_pass_ul .p-inputtext{
        width:60%;
    }

    /* 회원탈퇴 */
    #del_acount ul{
        padding : 0;
    }
    #del_acount h1{
        font-size : 20px;
    }
    #del_acount h3{
        font-size : 14px;
    }
}

/* phone */
@media(max-width : 415px){
    .mypage_main_div{
        padding-top: 20px;
        padding-left: 1%;
        padding-right: 1%;    
    }
    /* 프로필 이미지 */
    #mypage_profile_img{
        width: 250px;
        max-height: 250px;
    }
    /*******탭메뉴************/
    body .p-tabmenu .p-tabmenu-nav{
        font-size: 12px;
    }
    /* 패스워드 */
    .mypage_pass_ul .p-inputtext{
        width:80%;
    }

    #del_acount h1{
        font-size : 18px;
    }
    #del_acount h3{
        font-size : 12px;
    }
}

@media(max-width : 361px){
    /* 프로필 이미지 */
    #mypage_profile_img{
        width: 200px;
        max-height: 200px;
    }
    /*******탭메뉴************/
    body .p-tabmenu .p-tabmenu-nav{
        font-size: 10px;
    }
    /* 패스워드 */
    .mypage_pass_ul .p-inputtext{
        width:80%;
    }
}