/***************************** 회원가입  *************************************/
#main_div{
    text-align: center;
}

#signup_div{
    text-align: left;
    margin-top: 50px;
    padding : 0px;
    width: 500px;
    display: inline-block;
}
/* 제목 */
#sign_main_title{
    margin-bottom: 30px;
}
/* 입력창 */
.input_title{
    font-size: 14px;
    font-weight: bold;
}
/* 입력창 아이콘 */
.input_title .pi{
    font-size : 14px;
}
/* 설명 텍스트 */
.input_sub{
    font-size:12px;
    font-weight: lighter;
    margin-bottom: 15px;
}
/* 입력 필드 크기 */
.input_filed{
    width: 500px;
}
/* 동의 화면 */
#signup_agree{
    width : 450px;
    margin-top: 200px;
    margin-left: 100px;
    display: inline-block;
    vertical-align: top;
}
#signup_agree ul{
    list-style: none;
}
#signup_agree li{
    margin-bottom: 50px;
}
#signup_agree #signup_long_text{
    /* white-space: normal; */
    word-break : keep-all;
    width: 100%;
}
/* 동의 화면 링크  */
#signup_agree_link_div{
    text-align: center;
}
#signup_agree_link_div span{
    padding-left: 10px;
    padding-right: 10px;
}

/* 초대코드 툴팁 */
#signup_tooltip{
    margin-top: 500px;
    margin-left: 100px;
    display: inline-block;
    vertical-align: top;
}
/* 회원가입 완료 다이얼로그 */
/* 컨테이너 */
#main_div .sign_dialog{
    width: auto;
}
/* 내부 바디 */
#main_div .p-dialog .p-dialog-content{
    border-radius: 20px;
}
/* 좌우 아이템 */
#main_div .p-dialog .p-dialog-content .suc_div{
    display: inline-block;
    width : 300px;
    vertical-align: middle;
}
/* 텍스트 */
#main_div .p-dialog .p-dialog-content .suc_div h1{
    margin : 0px;
}
#main_div .p-dialog .p-dialog-content .suc_div p{
    margin : 0px;
    margin-bottom: 20px;
}
#main_div .sign_dialog .Nav-Item-Link {
    padding : 5px 5px 5px 5px;
    margin : 5px 5px 5px 5px;
    border: 1px solid rgb(22, 182, 49);
    border-radius: 3px;

    background-color : rgb(22, 182, 49);

    color : white;    
}

/* *********************************로그인****************************************** */
/* 전체 박스 */
#signin_div{
    display: inline-block;
    margin-top: 100px;
    width: 350px;
}
/* 입력창 속성 */
.signin_input_filed{
    width: 300px;
}
/* 입력창 속성 위쪽 타이틀 */
.signin_title{
    font-size: 14px;
    font-weight: bold;
    float: left;
    margin-left : 15px;
}
/* 로그인 버튼 */
#signin_btn{
    width: 300px;
    margin-bottom: 10px;
}
/* 로그인창에서 회원가입 유도 창 */
#signin_to_signup{
    display: block;
    margin-top: 20px;
    width: 350px;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 3px;
}
/* 로그인창 패스워드 찾기 */
#signin_forgot_pw{
    float: right;
    font-size: 12px;
    font-weight: bold;
    padding-top: 3px;
    padding-right : 10px;
}
/* 해상도 대응 */
/* pc */
@media(max-width: 1367px){

}

/* tablet */
@media(max-width : 1025px){
    /* 회원가입 관련 */
    #signup_agree{
        width : 300px;
        margin-top: 200px;
        margin-left: 30px;
    }
    #signup_agree .p-card{
        width: 250px;
    }
    /* 회원가입 완료 다이얼로그 */
    /* 좌우 아이템 */
    #main_div .p-dialog .p-dialog-content .suc_div{        
        width : 230px;        
    }
    /* 텍스트 */
    #main_div .p-dialog .p-dialog-content .suc_div h1{
        margin-top: 10px;
        font-size: 20px;
    }
    #main_div .p-dialog .p-dialog-content .suc_div p{
        font-size: 14px;
    }
    
}

@media(max-width : 769px){
    /* 회원가입 관련 */
    #signup_div{        
        margin-top: 10px;
    }

    #signup_agree{
        width: 100%;
        margin-top: 30px;
        margin-left: 0px;
    }
    #signup_agree .p-card{
        width: 250px;
        height: 200px;
    }
    #signup_agree ul{
        margin:0;
        padding:0;
    }
    #signup_agree li{
        display: inline-block;
        margin-bottom: 0px;
        vertical-align: top;
    }
    /* 회원가입 완료 다이얼로그 */    
    /* 텍스트 */
    #main_div .p-dialog .p-dialog-content .suc_div h1{
        margin-top: 10px;
        font-size: 16px;
    }
    #main_div .p-dialog .p-dialog-content .suc_div p{
        font-size: 12px;
    }
}

/* phone */
@media(max-width : 415px){
    /* 로그인관련 */
    #signin_div{
        margin-top: 30px;
        width: 90%;
    }
    /* 입력창 속성 */
    .signin_input_filed{
        width: 90%;
    }
    /* 로그인 버튼 */
    #signin_btn{
        width: 90%;
    }
    /* 로그인창에서 회원가입 유도 창 */
    #signin_to_signup{
        margin-top: 20px;
        width: 100%;
    }

    /* 회원가입 관련 */
    #signup_div{ 
        width: 90%;
    }
    /* 제목 */
    #sign_title{
        font-size : 20px;
    }
    /* 부제 */
    #sign_subtitle{
        font-size: 14px;
    }

    /* 입력 필드 크기 */
    .input_filed{
        width: 100%;
    }
    /* 동의화면 */
    #signup_agree .p-card{
        width: 90%;
        margin-left: 5%;
        height: auto;
    }
    #signup_agree li{
        margin-bottom: 10px;
    }

    /* 회원가입 완료 다이얼로그 */
    /* 좌우 아이템 */
    #main_div .p-dialog .p-dialog-content .suc_div{        
        width : 200px;        
    }
    
}