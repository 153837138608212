#word_cloud_main{
    width: 100%;
    height: 300px;
    background-color : black;
}

@media(max-width: 1366px){
    
}

/* tablet */
@media(max-width : 1025px){
    /* 워드클라우드 */
    #word_cloud_main{
        padding-top: 10px;
        height: 250px;
    }
    #word_cloud_main svg{
        height: 230px;
    }
    
}
/* tablet */
@media(max-width : 769px){
    #word_cloud_main{
        padding-top: 10px;
        height: 220px;
    }
    #word_cloud_main svg{
        height: 200px;
    }
}

/* phone */
@media(max-width : 415px){
    #word_cloud_main{
        padding-top: 10px;
        height: 170px;
    }
    #word_cloud_main svg{
        height: 150px;
    }
}