#map_div{
    width: 70%;
}
#contect_main{
    margin-top: 70px;
    margin-left : 10%;
    margin-right: 10%;
    margin-bottom: 50px;
    padding-top : 10px;
    padding-bottom : 60px;
    
}
#contect_main #conetct_google{
    width: 50%;
}
#contect_main ul{
    padding : 0;
    list-style: none;
}

#conetct_non_map{
    padding-top:500px;
}

@media(max-width : 415px){
    #contect_main{
        margin-top: 50px;
    }
}