/* 모바일 환경에서 클릭 버튼 */
#MENU{
    width: 100%;
    height: 100%;
    vertical-align: middle;
}
#MENU #mobile_menu{
    width: 100%;
    height: 100%;
    font-size: 20px;
    color : white;
    font-weight: bold;
    border: 0px;
    background-color : rgba(255,255,255,0);   
    
}
.side_bar_content{
    position: relative;
}

/* 사이드바 관련 스타일 정의 */
/* .side_bar_content .p-sidebar{
    height: 100%;
} */
.side_bar_content .menu_list_ul{
    list-style: none;
    line-height: 70px;
    padding : 0;
    margin: 0;
}
.side_bar_content .menu_list_ul #menu_list_item{
    border-bottom: 1px solid rgb(230, 230, 230);
}

.side_bar_content .menu_list_ul .menu_item{
    color : black;
    font-size: 20px;
    font-weight: bold;
}

/* 검색 */ 
.side_bar_content .menu_list_ul #nav_search{
    display: block;
}
.side_bar_content .menu_list_ul #nav_search input{
    width: 100%;
}
/* 언어 변경 */
.side_bar_content .menu_list_ul .menu_flag{
    background-color: transparent;
    border : none;
}
/* 검색 */
#m_search_dropdown div h3{
    width: 170px;
}
/* 하단 로그인 버튼 */
/* 로그인 전 */
.menu_btn_ul{
    padding :0;
    position: relative;
    list-style: none;
    float: left;
    color : black;
    width: 100%;
}

.menu_btn_ul li{
    width: 45%;
    display: inline-block;
    font-size: 14px;
    text-align: center;

    
    padding : 5px 5px 5px 5px;
    margin : 0 5px 0 5px;

    border: 1px solid gray;
    border-radius: 5px;

    text-decoration: none;
}

/* 로그인후 */
.menu_btn_ul_logout {
    display: inline-block;
    
}
.menu_btn_ul_logout .p-button.p-button-text-only .p-button-text{
    padding-left: 0px;
}
/* 이미지 */
#menu_profile_img{
    margin-top: 20px;
    width: 100%;
    height: 200px;
    border-radius: 10px;
}




/* 반응형 디자인 */
/* pc */
@media(max-width: 1366px){
    
}
/* tablet */
/* pad_pro */
@media(max-width : 1024px){
    /* 유저 이미지 */
    .m_nav_user_img{
        text-align: center;
    }
    #menu_profile_img{
        width: 80%;
        height: 180px;
    }
    /* 리스트 */
    .side_bar_content .menu_list_ul{
        line-height: 60px;
    }
    .side_bar_content .menu_list_ul .menu_item{
        font-size: 18px;
    }
    
    
}
/* phone */
/* iphone- x */
@media(max-width : 415px){
    /* 프로필 이미지 */
    #menu_profile_img{
        width: 50%;
        height: 100px;
    }

    /* 리스트 */
    .side_bar_content .menu_list_ul{
        line-height: 50px;
    }
    .side_bar_content .menu_list_ul .menu_item{
        font-size: 16px;
    }


}

@media(max-width: 361px){
    #Mobile_Menu{
        margin-left: 0px;
    }
    #MENU #mobile_menu{
        font-size: 16px;
    }
}