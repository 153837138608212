/* 기본 큰틀 */
.tech_content_root{
    margin-top: 70px;
    text-align: center;
}
/* 타이틀 */
.tech_type_div{
    max-width: 1200px;
    margin : 0 auto;
}
.tech_type_div h1{
    border: 1px solid gray;
    border-radius: 10px;

    display: inline-block;
    padding : 5px 5px;
    font-size: 18px;
}
/* 하단 콘텐츠 */
.list_div{
    max-width: 1200px;
    margin : 0 auto;
}

/* 페이지네이션  */
#pagination_div .p-paginator{
    background-color: rgb(245, 245, 245);
    margin-bottom: 0;
}

/* 반응형 디자인 */
/* 모든 콘텐츠를 하나의 css로 관리할 수 있었지만 세부적인 관리를 위해서 개별화 진행 */
@media(max-width: 1366px){
    /* 커버 제외 하단 아이템이 들어가는 박스 */
    /* 홈 콘텐츠 7% , 테크 콘텐츠는 5%를 줌으로써 미묘하게 컨텐츠 내용이 커진다.
    이게 미묘할지 몰라도, 사용자에게 본격적으로 콘텐츠 내용을 보게하는 느낌을 주는 것을 노린다! */
    
}

/* tablet */
@media(max-width : 1025px){
    .list_div{
        padding : 0px 10% 0px 10%;
    }
}
/* tablet */
@media(max-width : 769px){
    .tech_type_div{
        text-align: center;
    }
    .list_div{
        padding : 0px 5% 0px 5%;
    }
}

/* phone */
@media(max-width : 415px){
    .tech_type_div h1{        
        padding : 3px 3px;
        font-size: 16px;
    }
    .list_div{
        padding : 0px 10px 0px 10px;
    }
    .tech_content_root{
        margin-top: 50px;
    }   
}