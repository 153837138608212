#default_div{
    margin-top: 70px;
    padding-bottom: 70px;
    background-color: rgb(245, 245, 245);
}


/* 반응형 디자인 */
/* pc */
@media(max-width: 1367px){
   
}

/* tablet */
@media(max-width : 1025px){
   
}
/* tablet */
@media(max-width : 769){
   
}

/* phone */
@media(max-width : 415px){
    #default_div{
        margin-top: 50px;
    }
}